
<template>
  <common-trs-page-container :padding="0">
    <template v-slot:page-content>
      <!-- <common-app-page-header
        :title="'Edit Share Term'"
      /> -->
      <v-card-title class="mt-3 pt-6 pl-8">
        <span
          class="pr-3 cursor-pointer"
          @click="close"
        > <v-icon>
          mdi-arrow-left</v-icon></span> <span class="trsText--text font-weight-medium text-h4">Edit Share Term</span>
      </v-card-title>
      <share-term-form
        v-model="shareTerms"
        :share-term-id="id"
        :loading="loading"
        :internal-dialog="internalDialog"
        :mode="'edit'"
        :is-submit="isSubmit"
        :financing-rounds="financingRoundList"
        @save="update"
        @navToList="navToList"
      />
    </template>
  </common-trs-page-container>
</template>

<script>
  import * as captableService from '@/components/common/captable/services/captable'
  import ShareTermForm from './components/ShareTermForm'
  export default {
    name: 'EditShareTerms',

    components: {
      ShareTermForm,
    },

    data: () => ({
      loading: false,
      financingRoundList: [],
      shareTerms: {},
      isSubmit: false,
      internalDialog: false,
    }),

    computed: {
      captableId () {
        return this.$store.getters['auth/capTableId']
      },
      id () {
        return this.$route.query.id
      },
    },

    created () {
      this.loadData()
      this.loadFinancingRounds()
    },

    methods: {
      async loadFinancingRounds () {
        try {
          const resp = await captableService.getAllFinancingRounds(this.captableId)
          if (resp && resp.data) {
            this.financingRoundList = resp.data.financing_rounds
          }
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data.',
          })
        }
      },

      async loadData () {
        this.loading = true
        try {
          const resp = await captableService.getShareTermById(this.captableId, this.id)
          this.shareTerms = resp.data
          this.internalDialog = true
          this.loading = false
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to save financing round. Please try again later.',
          })
        }
      },

      async update (data) {
        this.loading = true
        try {
          const resp = await captableService.updateShareTerm(this.captableId, this.id, data)
          if (resp.status === 204) {
            this.isSubmit = true
          }
        } catch (err) {
          this.loading = false
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Sorry! Unable to save Share terms. Please try again later.',
          })
        }
      },
      navToList () {
        this.loading = false
        this.$store.dispatch('app/message', { text: 'Share terms saved successfully.' })
        this.$router.push({ name: 'ShareTerms' })
      },
      close () {
        this.$router.push({ name: 'ShareTerms' })
      },
    },
  }
</script>
